<template>
  <a-modal :maskClosable="false" title="选择商品" style="top: 8px;" width="80%" v-model="visible" @ok="toSubmit">
    <div class="query">
      <a-form-model :labelCol="{span: 7}" :wrapperCol="{span:17}">
        <a-row :gutter="4">
          <!-- <a-col :md="8">
            <a-form-model-item label="物料编号">
              <a-input v-model.trim="queryParam.goodsCode" placeholder="物料编号" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col :md="8">
            <a-form-model-item label="编码名称">
              <a-input v-model.trim="queryParam.cusCodeName" placeholder="请输入经销商编码名称" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8">
            <a-form-model-item label="图号">
              <a-input v-model.trim="queryParam.chartCount" placeholder="请输入图号" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col :md="8">
            <a-form-model-item label="车型描述">
              <a-input v-model.trim="queryParam.name" placeholder="请输入车型描述" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col :md="8">
            <a-form-model-item label="OE码">
              <a-input v-model.trim="queryParam.oeCode" placeholder="请输入OE码" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8">
            <a-form-model-item label="排量">
              <a-select v-model="carDisplac" style="width: 100%" placeholder="请选择" @change="handleChange">
                <a-select-option :value="item.id" v-for="(item, index) in displacement" :key="index">{{item.title}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <a-col :md="8">
            <a-form-model-item label="商品名称">
              <a-input v-model.trim="queryParam.productName" placeholder="请输入商品名称" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :md="8">
            <a-form-model-item label="商品分类">
              <a-cascader :options="categoryList" v-model="categoryValue" :field-names="{ label: 'name', value: 'id', children: 'children' }" placeholder="请选择" @change="onCategoryChange" />
            </a-form-model-item>
          </a-col>
          <a-col :md="8">
            <a-form-model-item label="车信息">
              <a-cascader :options="options" v-model="carinfo" :field-names="{ label: 'name', value: 'id', children: 'children' }" placeholder="请选择" @change="onSelectChange" />
            </a-form-model-item>
          </a-col> -->
          <a-col :md="8">
            <a-form-model-item :labelCol="{span: 0}" :wrapperCol="{span:22,offset:1}">
              <a-button @click="toSearch(0)" type="primary" icon="search">查询</a-button>
              <a-button @click="toSearch(1)" type="default" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div class="table">
      <a-table width="68%" :data-source="tableData" bordered rowKey="id" :pagination="page" @change="p=>{page=p;toSearch(2)}"
        :row-selection="{fixed: true,type: 'checkbox',selectedRowKeys:selectedRowKeys,onChange:
        (keys,rows)=>{selectedRowKeys=keys;selectedRows= rows}}"
        :customRow="record=>{return{on:{click:e=>{let index=this.selectedRowKeys.indexOf(record.id);index>=0?selectedRowKeys.splice(index, 1):selectedRowKeys.push(record.id);index>=0?selectedRows.splice(index, 1):selectedRows.push(record);}}}}">
        <a-table-column title="CNC商号" data-index="cncCompany" :width="150" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="商品名称" data-index="name" :ellipsis="true" align="center">
          <template slot-scope="text">
            <a-tooltip> <template slot="title"> {{text}} </template> {{text}} </a-tooltip>
          </template>
        </a-table-column>
        <a-table-column title="规格型号" data-index="specStr" :width="200" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="总成号" data-index="assemblyNumber" :width="200" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="适用品牌" data-index="carBrand" :width="120" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="适用车型" data-index="carModel" :width="200" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="图片" data-index="imgUrl" :width="120" :ellipsis="true" align="center">
          <template slot-scope="text, record">
            <ZoomMedia style="height: 20px;" :value="text"></ZoomMedia>
          </template>
        </a-table-column>
        
        <!-- <a-table-column title="商品分类" data-index="categoryName" :width="200" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="市场价" data-index="sellPrice" :width="100" :ellipsis="true" align="right">
        </a-table-column>
        <a-table-column title="标准开票价（D价）" data-index="price" :width="100" :ellipsis="true" align="right">
        </a-table-column>
        <a-table-column title="库存" data-index="realQuantity" :width="100" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="起订量" data-index="minQuantity" :width="100" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="限购量" data-index="maxQuantity" :width="100" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="车系" data-index="carSeries" :width="120" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="排量" data-index="carDisplacement" :width="120" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="图号" data-index="chartCount" :width="150" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="OE码" data-index="oeCode" :width="150" :ellipsis="true" align="center">
        </a-table-column> -->
      </a-table>
    </div>
  </a-modal>
</template>
<script>
  const qs = require('qs')
  export default {
    data() {
      return {
        queryParam: {},
        resetQueryParam: null,
        tableData: [],
        loading: false,
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
        },
        options: [],
        selectedRowKeys: [],
        selectedRows: [],
        visible: false,
        cusId:0,
        carinfo: [],
        categoryList: [],
        categoryValue: [],
        displacement: [],
        carDisplac: []
      }
    },
    components: {},
    methods: {
      show(ids, cusId) {
        if (!cusId) {
          this.$message.error("请先选择经销商")
        }
        this.visible = true
        this.selectedRowKeys = ids
        this.cusId=cusId
        this.toSearch(1)
        this.axios.get('/api/product/car/carBaseInfo/list?flagEnable=true').then(res => {
          this.options = res.body[0] ? res.body[0].children : []
        })
        this.axios.get('/api/product/category/list?flagEnable=true').then(res => {
          this.categoryList = res.body[0] ? res.body[0].children : []
        })
        this.axios.get('/api/base/system/dictionary/selectByCode/排量').then(res => {
          this.displacement = res.body
        })
      },
      becomeGoods(x){
        let tmp={
          id:x.goodsId,
          name:x.productName,
          imgUrl:x.goodsImage,
          specStr:x.specStr,
          sellPrice:x.sellPrice,
          price:x.productPrice,
          realQuantity:x.realQuantity,
          minQuantity:x.minQuantity,
          maxQuantity:x.maxQuantity,
          productPrice:x.productPrice,
          carBrand: x.carBrand,
          carSeries: x.carSeries,
          carModel: x.carModel,
          chartCount: x.chartCount,
          oeCode: x.oeCode,
          categoryName: x.categoryName,
          carDisplacement: x.carDisplacement,
          cncCompany: x.cncCompany,
          assemblyNumber: x.assemblyNumber
        }
        return tmp
      },
      toSearch(type) {
        if (type == 1) {
          this.resetQueryParam ? this.queryParam = Object.assign({}, this.resetQueryParam) : this.resetQueryParam =
            Object.assign({}, this.queryParam)
            this.carinfo = []
            this.categoryValue = []
            this.carDisplac = []
        }
        if (type != 2) {
          this.selectedRowKeys = []
          this.selectedRows = []
        }
        this.page.pageNumber = type == 2 ? this.page.current : this.page.current = 1
        const postData = Object.assign({}, this.queryParam, this.page,{cusId:this.cusId})
        this.axios.get(`/api/customer/customer/customerPricePolicy/listByCustomer?${qs.stringify(postData)}`).then(res => {
          let tmpArr=res.body.records.map(x=>this.becomeGoods(x))
          this.tableData =tmpArr
          this.page.total = res.body.total
        }).catch(err => {})
      },
      toSubmit() {
        this.axios.get(`/api/customer/customer/customerPricePolicy/selectListByGoodsIdsAndCusId?goodsIds=${this.selectedRowKeys}&customerId=${this.cusId}`, ).then(res => {
          let tmpArr=res.body.map(x=>this.becomeGoods(x))
          this.$emit('select', tmpArr)
          this.visible = false
        }).catch(err => {})
      },
      onSelectChange(value) {
        this.queryParam.carBrandId = value ? value[0] : ''
        this.queryParam.carSeriesId = value ? value[1] : ''
        this.queryParam.carModelId = value ? value[2] : ''
      },
      onCategoryChange(value) {
        var reg = new RegExp(",","g")
        this.queryParam.categoryIdStr = value.map(i => {return i}).toString().replace(reg, '_')
      },
      handleChange(value) {
        this.queryParam.carDisplacementId = value
      }
    },
    created() {

    },

  }
</script>


<style lang="less" scoped>
</style>
